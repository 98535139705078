/**
 * Google Map Integration
 */
$(function () {

    var map;
    var map_styles = [{"stylers": [{"hue": "#00a7e3"}, {"saturation": 250}]}, {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{"lightness": 50}, {"visibility": "simplified"}]
    }, {"featureType": "road", "elementType": "labels", "stylers": [{"visibility": "off"}]}];

    function initMap() {

        var canvas = document.getElementById('map-canvas');

        if (canvas == null) {
            return false;
        }

        var mapOptions = {
            zoom: 12,
            center: new google.maps.LatLng(50.885646, 6.917627),
            scrollwheel: false,
            draggable: !("ontouchend" in document),
            styles: map_styles,
            disableDefaultUI: true,
            zoomControl: true
        };
        map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

        var addresses = [
            {
                lat: 49.5267261,
                lng: 8.4464666,
                address1: 'Network Logistics GmbH (NLG) – Hauptsitz Mannheim',
                address2: 'Ölhafenstr. 30-32',
                address3: '68169 Mannheim',
                href: 'https://www.google.de/maps/place/Network+Logistics+GmbH/@49.5267261,8.4464666,17z/data=!3m1!4b1!4m5!3m4!1s0x4797cd980f08a24b:0x24196002c135e888!8m2!3d49.5267226!4d8.4486606?hl=de'
            },
            {
                lat: 48.8876518,
                lng: 8.2422112,
                address1: 'Network Logistics GmbH (NLG) – Betriebsstätte Ötigheim (bei Karlsruhe)',
                address2: 'Werner-von-Siemens-Str. 5',
                address3: '76470 Ötigheim',
                href: 'https://www.google.de/maps/search/Werner-von-Siemens-Stra%C3%9Fe+5,+76470,+%C3%96tigheim/@48.8876518,8.2422112,15z/data=!3m1!4b1?hl=de'
            }
        ];

        var bounds = new google.maps.LatLngBounds();

        var infowindow = new google.maps.InfoWindow({
            content: '',
            maxWidth: 200
        });

        for (var i = 0; i < addresses.length; i++) {

            var address = addresses[i];

            // Create marker
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(address.lat, address.lng),
                icon: '/images/map-marker.png'
            });

            // Set marker on map
            marker.setMap(map);

            // Add html to the marker
            marker.html =
                '<div class="info-window-body">' +
                '<b>' + address.address1 + '</b>' + '<br>' +
                address.address2 + '<br>' +
                address.address3 + '<br>' +
                '<div class="m-t-15"><a class="btn btn-block btn-secondary" href="' + address.href + '" target="_blank">Google Maps</a></div>' +
                '</div>';

            // Open infowindow by click
            marker.addListener('click', function () {
                infowindow.setContent(this.html);
                infowindow.open(map, this);
            });

            // Extend the map bounds
            bounds.extend(new google.maps.LatLng(address.lat, address.lng));

        }

        if (addresses.length > 1) {
            map.fitBounds(bounds);
        }
    }

    initMap();

});