/**
 * Cover Sections Filter
 */
$(function () {

    var $target;
    var $toggle = $('*[data-filter-action="true"]');

    $toggle.hover(function () {

        //$target = $($(this).data('filter-target') + ' > .bg-filter');

        $target = $(this).parents('.section').find('.bg-filter');

        $target.stop().animate({
            opacity: 1
        });
    }, function () {

        $target.stop().animate({
            opacity: 0
        });
    });

    // stoerer

    var $targets;

    $('.stoerer').hover(function () {

        $targets = $('.section').find('.bg-filter');

        $targets.stop().animate({
            opacity: 1
        });

    }, function () {
        $targets.stop().animate({
            opacity: 0
        });
    });

});