/**
 * Section Slide Navigation
 */
$(function () {

    var $window = $(window);
    var $nav = $('.nav-section');
    var $slides = $('*[data-action="slide"]');

    var $navIndicator = $nav.find('.indicator');
    var $navPrev = $nav.find('.nav-control.prev > button');
    var $navNext = $nav.find('.nav-control.next > button');

    var slidesTotal = $slides.length;
    var slideCurrent = 1;

    var setCurrent = function (value) {
        slideCurrent = value;
        renderNav();
    };

    var renderIndicator = function () {
        $navIndicator.html(slideCurrent + ' | ' + slidesTotal);
    };

    var scrollToSlide = function () {

        var duration = scroll_speed;

        var header_height = $('.header').outerHeight(true);

        var offset = $($slides[slideCurrent - 1]).offset();
        var scroll_position = offset.top - header_height;

        var hash = $($slides[slideCurrent - 1]).attr('id');

        $('html, body').stop().animate({
            scrollTop: scroll_position
        }, {
            duration: duration,
            complete: function () {

                // update url hash
                if (hash != undefined) {
                    history.pushState('', document.title, window.location.pathname + '#' + hash);
                } else {
                    history.pushState('', document.title, window.location.pathname);
                }
            }
        });

    };

    var renderNav = function () {

        // Check disabled
        $navPrev.removeClass('disabled');
        $navNext.removeClass('disabled');

        if (slideCurrent == 1) {
            $navPrev.addClass('disabled');
        }

        if (slideCurrent == slidesTotal) {
            $navNext.addClass('disabled');
        }

        // Theme
        $currentSlide = $($slides[slideCurrent - 1]);

        var classArray = $currentSlide.attr('class').split(' ');

        if ($.inArray('section-light', classArray) != -1 || $.inArray('section-default', classArray) != -1) {
            $nav.addClass('nav-section-dark');
        } else {
            $nav.removeClass('nav-section-dark');
        }

        // Indicator
        renderIndicator();
    };

    $navPrev.click(function () {

        if (slideCurrent <= 1) {
            return false;
        }

        slideCurrent--;

        scrollToSlide();
    });

    $navNext.click(function () {


        if (slideCurrent >= slidesTotal) {
            return false;
        }

        slideCurrent++;

        scrollToSlide();
    });

    $window.scroll(function () {

        var scrollTop = $(this).scrollTop();

        $slides.each(function (key, val) {

            var $slide = $(val);

            var header_height = $('.header').outerHeight(true),
                offset = $slide.offset();

            if (scrollTop >= offset.top - header_height - 1) {
                setCurrent(key + 1);
            }

        });

    });

    renderIndicator();

});