/**
 * Hash Navigation
 */
$(function () {
    /**
     * Update Active Submenu Item
     */
    var updateActiveSubmenuItem = function () {

        var $dropdownItemLinks = $('.navbar-main .dropdown-menu li a');

        var hash = window.location.hash;

        $.each($dropdownItemLinks, function (key, el) {

            var $link = $(el);
            var $li = $link.parent('li');

            var link_hash = '#' + $link.attr('href').split('#')[1];

            if (hash == link_hash) {
                $li.addClass('active');
            } else {
                $li.removeClass('active');
            }
        });
    };


    if (window.location.hash) {

        $target = $(window.location.hash);

        setTimeout(function () {

            var offset = $target.offset();
            var header_height = $('.header').outerHeight();
            var scroll_position = offset.top - header_height;

            $('html, body').stop().animate({
                scrollTop: scroll_position
            }, 0);

            updateActiveSubmenuItem();

        }, 500);
    }
});