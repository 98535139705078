/**
 * Dropdown Navigation
 */
$(function () {

    var duration = scroll_speed;

    var $dropdown = $('.navbar-main .dropdown-menu');
    var $header = $('.header');

    $dropdown.find('a').click(function (e) {

        e.preventDefault();

        var current_url = window.location.href.split('#')[0];

        var href = $(this).attr('href');
        var target_url = href.split('#')[0];
        var target_id = '#' + href.split('#')[1];

        if (current_url == target_url) {
            var $target = $(target_id);

            var offset = $target.offset();

            var header_height = $header.outerHeight();

            var scroll_position = offset.top - header_height;

            var hash = $target.attr('id');

            $('html, body').stop().animate({
                scrollTop: scroll_position
            }, {
                duration: 0,
                complete: function () {

                    // update url hash
                    if (hash != undefined) {
                        history.pushState('', document.title, window.location.pathname + '#' + hash);
                    } else {
                        history.pushState('', document.title, window.location.pathname);
                    }
                }
            });

        } else {
            window.location = target_url + target_id;
        }

    });
});