/**
 * Set height for cover and fullsize sections
 */
$(function () {
    var $mobile_marker = $('<div id="mobile-marker"></div>').appendTo('body');

    var $window = $(window);
    var $header = $('.header');
    var $ccbanner = $('.cc_banner-wrapper');

    var $cover = $('.section-cover');
    var $fullpage = $('.section-fullpage');

    var setSectionHeight = function () {
        var height = $window.outerHeight() - $header.outerHeight(true) + $ccbanner.innerHeight();
        var width = $window.outerWidth();

        if (!$mobile_marker.is(':visible')) {
            $cover.css('height', height);
            $fullpage.css('height', height);
        } else {
            // reset
            $cover.css('height', '');
            $fullpage.css('height', '');
        }
    };

    $(window).on('resize', setSectionHeight);

    setTimeout(setSectionHeight, 100);
});

//var attr = $mobile_marker.is(':visible') ? 'min-height' : 'height'; // ("ontouchend" in document) <- ???

//$cover.css('height', '').css('min-height', '').css(attr, height);
//$fullpage.css('height', '').css('min-height', '').css(attr, height);