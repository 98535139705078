/**
 * Scroll To Slide
 */
$(function () {

    $('*[data-action="scroll-to-slide"]').click(function (e) {

        e.preventDefault();

        var duration = scroll_speed;

        var target_no = $(this).data('slide');

        var $sections = $('section');

        //console.log(target_no);
        var $target = $($sections[target_no - 1]);

        if (!$target.length) {
            return false;
        }

        var offset = $target.offset();
        var header_height = $('.header').outerHeight();

        var scroll_position = offset.top - header_height;

        var hash = $target.attr('id');

        $('html, body').stop().animate({
            scrollTop: scroll_position
        }, {
            duration: duration,
            complete: function () {

                // update url hash
                if (hash != undefined) {
                    history.pushState('', document.title, window.location.pathname + '#' + hash);
                } else {
                    history.pushState('', document.title, window.location.pathname);
                }
            }
        });

        $(this).blur();
    });

});