/**
 * Observe Sections On Scroll
 */
$(function () {

    var $sections = $('.section');

    var header_height = $('.header').outerHeight();

    $(window).scroll(function () {

        var scroll = $(window).scrollTop();

        $sections.each(function () {

            var $section = $(this);

            var pos = $section.position();

            if (pos.top <= scroll + header_height) {

                var id = $section.attr('id');

                $('.dropdown-menu li').removeClass('active');

                // Find nav item
                $('.dropdown-menu li a').each(function () {
                    var href = $(this).attr('href');

                    var hash = href.split('#')[1];

                    if (hash == id) {
                        $(this).parent().addClass('active');
                    }
                });

            }

        });

    });

});
