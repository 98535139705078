// Global vars
var scroll_speed = 0;

$(function () {
    // init skrollr
    var initSkrollr = function () {
        if (!("ontouchend" in document)) {
            var s = skrollr.init();
        }
    };
    setTimeout(initSkrollr, 110); // delay to wait for other calculations


    // init lightbox
    $('[rel="lightbox"]').lightbox();
});