/**
 * Slick Slider
 */
$(function () {

    // vars

    var $slider = $('.slider');

    // methods

    var getTotalSlickPages = function (slick) {

        //var page_count = Math.round(slick.slideCount / getSlidesToShow(slick));
        var page_count = Math.ceil(slick.slideCount / getSlidesToShow(slick));

        // fix rounding issue
        if (page_count <= 1 && slick.slideCount > getSlidesToShow(slick)) {
            page_count = 2;
        }

        return page_count;
    };

    var getCurrentSlickPage = function (slick) {
        return Math.round(slick.slickCurrentSlide() / getSlidesToShow(slick)) + 1;
    };

    var getSlidesToShow = function (slick) {

        var return_val = null;

        if (slick.options.responsive != 'undefined') {
            $.each(slick.options.responsive, function (key, value) {
                if (screen.width <= value.breakpoint) {
                    return_val = value.settings.slidesToShow;
                }
            });
        } else {
            return_val = slick.options.slidesToShow;
        }

        return return_val != null ? return_val : slick.options.slidesToShow;
    };

    var getPagerHtml = function (slick) {
        var current_page = getCurrentSlickPage(slick);
        var page_count = getTotalSlickPages(slick);

        if (page_count <= 1) {
            return '&nbsp;';
        } else if (current_page > page_count) {
            return page_count + ' | ' + page_count;
        } else {
            return current_page + ' | ' + page_count;
        }
    };

    var getPagerId = function (slick) {
        return slick.$slider.attr('id') + '-pager';
    };

    var getHighlightPosition = function (slick) {

        var pos = 1;
        var return_val = false;

        $.each(slick.$slides, function (key, el) {
            if ($(el).find('.highlight').length == 1) {
                return_val = pos;
            }

            pos++;
        });

        return return_val;
    };

    // events

    $slider.on('init', function (event, slick) {

        var $slider = slick.$slider;

        var $pager = $('<div>', {
            id: getPagerId(slick),
            class: 'slick-pager',
            html: getPagerHtml(slick)
        });

        $slider.after($pager);

        // Set highlighted item in view
        var highlight_item_pos = getHighlightPosition(slick);

        if (highlight_item_pos) {

            var slides_per_page = getSlidesToShow(slick);
            var go_to = Math.ceil(highlight_item_pos / slides_per_page);

            gotoNextSlide(slick, go_to);
        }

        resizeSlides(slick);
    });

    /**
     * Goto Next Slide
     */
    var gotoNextSlide = function (slick, clicks) {
        if (clicks == undefined) {
            clicks = 1;
        }

        if (--clicks > 0) {
            var current_speed = slick.options.speed;
            slick.options.speed = 10;
            slick.$slider.find('.slick-next').click();
            slick.options.speed = current_speed; // reset

            setTimeout(function () {
                gotoNextSlide(slick, clicks);
            }, 20);
        }
    };

    $slider.on('afterChange', function (event, slick) {
        $('#' + getPagerId(slick)).html(getPagerHtml(slick));
        resizeSlides(slick);
    });

    /**
     * Resize Slides
     */
    var resizeSlides = function (slick) {
        // Get max height
        var heights = [];

        slick.$slides.each(function () {
            var $item = $(this).children('.slide-item');
            $item.css('height', ''); // reset
            var height = $item.outerHeight(true);
            heights.push(height);
        });

        var height_max = Math.max.apply(null, heights);

        // Set height
        slick.$slides.each(function () {
            $(this).children('.slide-item').css('height', height_max);
        });
    };

    // init

    $slider.slick();
});