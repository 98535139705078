/**
 * Sticky Eyecatcher
 */
$(function () {
    var positionEyecatcher = function () {
        var $el = $('.footer'),
            $eyecatcher = $('.stoerer'),
            scrollTop = $(this).scrollTop(),
            scrollBot = scrollTop + $(this).height(),
            elTop = $el.offset().top,
            elBottom = elTop + $el.outerHeight(),
            visibleTop = elTop < scrollTop ? scrollTop : elTop,
            visibleBottom = elBottom > scrollBot ? scrollBot : elBottom,
            visibleElementHeight = visibleBottom - visibleTop

        if (visibleElementHeight > 0) {
            $eyecatcher.css({
                'bottom': (visibleElementHeight) + 'px'
            });
        } else {
            $eyecatcher.css({
                'bottom': '0px'
            });
        }
    }

    $(window).on('scroll resize', positionEyecatcher);
    positionEyecatcher(); // initial
});