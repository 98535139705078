/**
 * Body padding for fixed header
 */
$(function () {
    var $body = $('body');
    var $header = $('.header');

    var setBodyOffset = function () {
        var height = $header.outerHeight(true);
        $body.css('padding-top', height);
    };

    var initialize = function () {
        setBodyOffset();
        $('.cc_btn').on('click', setBodyOffset); // resize on cookie consent closing
    };

    $(window).on('resize', setBodyOffset);
    setTimeout(initialize, 100); // delay to allow insertion of cookie consent
});
